<template>
    <span class="mx-1">
        <v-icon>
            mdi-pause
        </v-icon>
    </span>
</template>

<script>
export default {
    name: "PausedLabel"
}
</script>

<style scoped>

</style>